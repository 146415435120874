@import "~antd/dist/antd.less";

* {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: @primary;
    border-radius: 8px;

    &:hover {
      cursor: pointer;
    }
  }
}

html,
body,
#root {
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100vw;
}

p {
  margin-bottom: 0px !important;
}

.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  background: @grayscaleBorders;
}

.rk-layout-content {
  margin-top: 0px !important;
  background: @white;
  margin-left: 10px;
}

.rk-form {
  flex: 1;
  margin: 2vh 10vw;
  justify-content: center;
  height: 100hv;
}

.rk-button-primary {
  height: 44px;
  width: 147px;
  font-weight: 600;
  font-size: 14px;
}

.ant-table-thead {
  tr {
    th {
      color: @primary;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 14px !important;
    }
  }
}

.rk-menu-dropdown {
  padding: 0 !important;
  position: relative;
  right: 60px;
  border-radius: 4px;
  width: 160px;
  .ant-dropdown-menu-item-aqctive {
    background: @grayscaleBackgrounds !important;
  }
  .rk-menu-dropdown-item {
    width: 100%;
    height: 48px;
    > span {
      width: 90%;
      height: 48px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      border-bottom: 1px solid @grayscaleBorders !important;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;

      &:hover {
        font-weight: 600;
      }
    }
  }
}

.disabled-row {
  color: #c3c5cc;
}

.ant-descriptions-item-label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

// table {
//   margin: auto;
//   td,
//   th {
//     border: 0.5px solid #dde2ed !important ;
//   }
//   tr > th {
//     font-weight: bold;
//     background: #dde2ed;
//   }
// }

.rk-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

tr {
  td {
    padding: 10px;
  }
}

.rk-button-group {
  display: flex;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  border-top: 1px solid @grayscaleBorders;
  padding: 10px 16px;
  background-color: @white;
  text-align: right;
  gap: 5px;
  flex-direction: row-reverse;
}

.rk-descriptions-items {
  .ant-descriptions-item-label {
    line-height: 44px;
  }
}

.rk-center-spin {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rk-container-list {
  padding-left: 10px;
}

.rk-content-layout {
  min-height: 100vh;
  background: @grayscaleBorders;
}

.rk-tag {
  height: 30px;
  padding: 4px 12px;
  border-radius: 8px;
  text-align: center;
  font-size: 14px;
  > span {
    margin-left: 10px;
  }
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0px !important ;
}

.ant-form-item-with-help .ant-form-item-explain {
  min-height: 0 !important;
}

.ant-divider-horizontal {
  margin: 10px 0;
  border-top: 1px solid #dde2ed;
}

.rk-select-multiple {
  .ant-select-selection-item {
    display: none !important;
  }
}

.ant-alert {
  height: 80px;
  &.ant-alert-error {
    background-color: #fff2f0;
    border: 1px solid #ffccc7;
    .ant-alert-message {
      color: @redMain;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.rk-descriptions-info {
  border: 1px solid #dde2ed;
  padding: 15px;
  border-radius: 4px;
}

.rk-btn-link {
  padding: 0px;
  font-size: 12px;
  margin: 0px;
}


.ant-tooltip-inner {
  min-width: 30px;
    min-height: 32px;
    padding: 6px 8px;
    color: #fff;
    text-align: left;
    text-decoration: none;
    word-wrap: break-word;
    background-color: #0f1c30;
    border-radius: 4px;
    box-shadow: 0 1px 1px #e2e3ea75 inset, 0 0 8px #e2e3ea06;
    border-radius: 8px;
}
@black: #2A2A33;@grayscalePlaceholders: #77787E;@grayscaleBorders: #DDE2ED;@grayscaleBackgrounds: #EFF2F9;@grayFocused: #C3C5CC;@greenMain: #40CD87;@greenLight: #F3F9F6;@redMain: #ED6868;@redLight: #FAF2F1;@yellowDark: #EBC40E;@yellowLight: #F7F5ED;@veryLightGray: #fafafa;@lightGrayishBlue: #e2e3ea;@blueMain: #D9E6E1;@davy-grey: #555555;@very-light-gray: #e3e3e3;@font-family: Roboto;@primary: #0f1c30;@primary-1: #0f1c3020;@primary-color-hover: #0f1c3060;@primary-color-active: #0f1c3060;@primary-color-outline: #0f1c3060;@primary-lightest: lighten(@primary, 30%);@primary-lighter: lighten(@primary, 10%);@primary-dark: darken(@primary, 8%);@primary-darkest: darken(@primary, 10%);@secondary: rgb(79, 93, 117);@secondary-lightest: lighten(@secondary, 60%);@secondary-lighter: lighten(@secondary, 35%);@secondary-darker: darken(@secondary, 8%);@secondary-darkest: darken(@secondary, 10%);@text-color: @primary;@text-color-disabled: @secondary-lighter;@button-color: @secondary-lightest;@button-color-disabled: @secondary-lighter;@layout-header-background: #0f1c30;@primary-color: @primary;@processing-color: @primary-color;@normal-color: #d9d9d9;@white: #fff;@link-color: @primary;@text-color-secondary: @secondary;@border-radius-base: 4px;@border-color-base: #DDE2ED;@secondary-hover-color: #7283a0;@mentions-dropdown-menu-item-hover-bg: rgb(114, 131, 160);@menu-item-active-bg: #95a1b850;@input-color: @text-color;@input-hover-border-color: #e2e3ea;@box-shadow-base: 0 1px 1px #e2e3ea75 inset, 0 0 8px #e2e3ea06;