.rk-steps {
  height: 60px !important;

  .ant-steps-item {
    padding: 0 !important;
    position: relative !important;
    display: inline-block !important;
    flex: 1 1 !important;
    overflow: unset !important;
    vertical-align: top !important;
    width: 508px !important;
    height: 70px !important;
  }
  .ant-steps-item-content {
    position: relative !important;
    left: -12px !important;
    top: 18px !important;
    ::before {
      content: "" !important;
      position: absolute !important;
      top: 12px !important;
      right: 40px !important;
      width: 5px !important;
      height: 1px !important;
      background-color: @grayscaleBackgrounds!important;
    }
  }
  .step-item-before-none {
    .ant-steps-item-content {
      ::before {
        display: none !important ;
      }
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-content {
      ::before {
        background-color: @grayscaleBackgrounds !important;
      }
    }
  }
  .ant-steps-item-process {
    .ant-steps-item-content {
      ::before {
        background-color: @grayscaleBackgrounds!important;
      }
    }
  }

  .ant-progress-inner {
    width: 48.5px !important ;
    height: 48.5px !important ;
    font-size: 1px !important ;
  }
  .ant-progress-status-success
    .ant-progress-inner:not(.ant-progress-circle-gradient)
    .ant-progress-circle-path {
    stroke: @grayscaleBackgrounds!important;
    stroke-width: 1px !important ;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: @primary !important;
    border: 1px solid @grayscaleBackgrounds!important;
    span {
      color: @white !important;
      div {
        > span {
          color: @white !important;
        }
      }
    }
  }

  .ant-steps-item-finish .steps-item-active {
    ::before {
      content: "" !important;
      position: absolute !important;
      top: -10px !important;
      left: -11px !important;
      border: 0.3px solid @primary !important ;
      background-color: transparent !important;
      width: 48px !important;
      height: 48px !important;
      border-radius: 50% !important;
    }
  }

  .ant-steps-item-finish .steps-item-check {
    ::before {
      content: "" !important;
      position: absolute !important;
      top: 0px !important;
      left: 0px !important;
      background-color: transparent !important;
      border-radius: 50% !important;
    }
  }

  .ant-steps-item-process .ant-steps-item-icon {
    background-color: transparent !important ;
    border: 1px solid @primary !important ;
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    border: none !important;
    background-color: @grayscaleBackgrounds !important ;
  }
  .ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon {
    color: @primary!important;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: @primary!important;
    width: 20px !important;
    font-weight: 600 !important;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: @primary!important;
    width: 20px !important;
  }

  .ant-steps-item-icon {
    padding: 6px !important;
    width: 40px !important;
    height: 40px !important;
    margin-right: 0px !important;
    top: 10px !important;
    left: 0px !important;
    .ant-steps-icon {
      height: 100% !important;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      span {
        width: 35px !important;
        height: 30px !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
      }
    }

    :nth-last-of-type(1) {
      margin-left: 0px !important;
    }
    span {
      color: @primary!important;
      font-weight: 600 !important;
      font-size: 16px !important;
    }
  }
  .ant-steps-progress-icon {
    height: 100% !important;
    width: 100% !important;
  }
}

.rk-step-button {
  width: 138px !important;
  height: 48px !important;
  button {
    width: 100% !important;
    height: 100% !important;
  }
}

.ant-steps-item-title::after {
  width: 40px !important;
}
.rk-container-text-button {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.rk-circle {
  width: 12px !important;
  height: 12px !important;
  border-radius: 50% !important;
  background-color: @grayscaleBackgrounds !important;
  position: relative !important;
  top: 25px !important;
  &.all-checked {
    background-color: @primary !important;
  }
}

@black: #2A2A33;@grayscalePlaceholders: #77787E;@grayscaleBorders: #DDE2ED;@grayscaleBackgrounds: #EFF2F9;@grayFocused: #C3C5CC;@greenMain: #40CD87;@greenLight: #F3F9F6;@redMain: #ED6868;@redLight: #FAF2F1;@yellowDark: #EBC40E;@yellowLight: #F7F5ED;@veryLightGray: #fafafa;@lightGrayishBlue: #e2e3ea;@blueMain: #D9E6E1;@davy-grey: #555555;@very-light-gray: #e3e3e3;@font-family: Roboto;@primary: #0f1c30;@primary-1: #0f1c3020;@primary-color-hover: #0f1c3060;@primary-color-active: #0f1c3060;@primary-color-outline: #0f1c3060;@primary-lightest: lighten(@primary, 30%);@primary-lighter: lighten(@primary, 10%);@primary-dark: darken(@primary, 8%);@primary-darkest: darken(@primary, 10%);@secondary: rgb(79, 93, 117);@secondary-lightest: lighten(@secondary, 60%);@secondary-lighter: lighten(@secondary, 35%);@secondary-darker: darken(@secondary, 8%);@secondary-darkest: darken(@secondary, 10%);@text-color: @primary;@text-color-disabled: @secondary-lighter;@button-color: @secondary-lightest;@button-color-disabled: @secondary-lighter;@layout-header-background: #0f1c30;@primary-color: @primary;@processing-color: @primary-color;@normal-color: #d9d9d9;@white: #fff;@link-color: @primary;@text-color-secondary: @secondary;@border-radius-base: 4px;@border-color-base: #DDE2ED;@secondary-hover-color: #7283a0;@mentions-dropdown-menu-item-hover-bg: rgb(114, 131, 160);@menu-item-active-bg: #95a1b850;@input-color: @text-color;@input-hover-border-color: #e2e3ea;@box-shadow-base: 0 1px 1px #e2e3ea75 inset, 0 0 8px #e2e3ea06;