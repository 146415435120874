.header {
  display: flex;
  align-items: center;
  background-color: @primary !important;
  height: 64px !important;
  width: calc(100%);
  padding: 0 !important;
  .ant-menu-horizontal {
    border-bottom: 0px;
    line-height: 0px;
  }
  .ant-menu {
    height: 0vh;
    margin-top: 0px;
  }
}

.header-container {
  border-bottom: @border-color-base;
  display: flex;
  margin: 0 40px 0 20px;
  width: 100%;
  height: 100%;
}

.hamburger {
  padding: 0px 0px;
  height: 60px;
  width: 60px;
  align-items: center;
  align-content: center;
  display: flex;
  font-size: 20px;
  color: @white !important;
  &:hover {
    color: @white !important;
    border-bottom: 0px !important;
    cursor: pointer;
  }
}

.profile-container {
  display: flex;
  align-items: center;
  .profile-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    border-radius: 8px;
    background-color: #4f5d75;
    > span {
      color: @white;
      font-weight: 700;
      font-size: 20px;
    }
  }
  .profile-message {
    padding-left: 20px;
    width: 100%;
    height: 48px;
    line-height: 48px;
    *:first-child {
      color: @white;
      margin: 0px;
      line-height: 24px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 0px;
    }
    *:last-child {
      margin-top: 2px;
      line-height: 18px;
      color: @white;
      margin: 0px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.rk-menu {
  color: @white !important;
  margin-left: auto !important;
  border-bottom: 0px !important;
  height: 60px !important;
  > .ant-menu-item a {
    color: @white !important;
    &:hover {
      color: #272e3a !important;
      background-color: #dce0e7;
      height: 62px;
      background-color: #4f5d75 !important;
    }
  }
  .rk-menu-item {
    color: @white !important;
    svg {
      font-size: 20px;
    }
    &:hover {
      background-color: #dce0e7;
      height: 62px;
      background-color: #4f5d75 !important;
    }
    &:active {
      height: 62px;
      background-color: #dce0e7;
      background-color: #4f5d75 !important;
    }
    &:disabled {
      height: 62px;
      color: #dce0e7;
      background: #ccc;
    }
    :after {
      border-bottom: 0px !important;
    }
  }
}

@black: #2A2A33;@grayscalePlaceholders: #77787E;@grayscaleBorders: #DDE2ED;@grayscaleBackgrounds: #EFF2F9;@grayFocused: #C3C5CC;@greenMain: #40CD87;@greenLight: #F3F9F6;@redMain: #ED6868;@redLight: #FAF2F1;@yellowDark: #EBC40E;@yellowLight: #F7F5ED;@veryLightGray: #fafafa;@lightGrayishBlue: #e2e3ea;@blueMain: #D9E6E1;@davy-grey: #555555;@very-light-gray: #e3e3e3;@font-family: Roboto;@primary: #0f1c30;@primary-1: #0f1c3020;@primary-color-hover: #0f1c3060;@primary-color-active: #0f1c3060;@primary-color-outline: #0f1c3060;@primary-lightest: lighten(@primary, 30%);@primary-lighter: lighten(@primary, 10%);@primary-dark: darken(@primary, 8%);@primary-darkest: darken(@primary, 10%);@secondary: rgb(79, 93, 117);@secondary-lightest: lighten(@secondary, 60%);@secondary-lighter: lighten(@secondary, 35%);@secondary-darker: darken(@secondary, 8%);@secondary-darkest: darken(@secondary, 10%);@text-color: @primary;@text-color-disabled: @secondary-lighter;@button-color: @secondary-lightest;@button-color-disabled: @secondary-lighter;@layout-header-background: #0f1c30;@primary-color: @primary;@processing-color: @primary-color;@normal-color: #d9d9d9;@white: #fff;@link-color: @primary;@text-color-secondary: @secondary;@border-radius-base: 4px;@border-color-base: #DDE2ED;@secondary-hover-color: #7283a0;@mentions-dropdown-menu-item-hover-bg: rgb(114, 131, 160);@menu-item-active-bg: #95a1b850;@input-color: @text-color;@input-hover-border-color: #e2e3ea;@box-shadow-base: 0 1px 1px #e2e3ea75 inset, 0 0 8px #e2e3ea06;