.content-not-found {
  width: 100%;
  line-height: 1.4;
  flex: 1;
  justify-content: "center";
  height: "100hv";
  text-align: center;
  z-index: 10000;
}

.error-pages {
  max-width: 767px;
  max-height: 767px;
  height: 467px;
  margin: auto;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  z-index: 10000;
}

.internal-server-error {
  max-width: 767px;
  max-height: 767px;
  height: 467px;
  margin: auto;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  z-index: 10000;
}

h2 {
  font-size: 33px;
  font-weight: 200;
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 25px;
  letter-spacing: 3px;
}

p {
  font-size: 16px;
  font-weight: 200;
  margin-top: 0px;
  margin-bottom: 25px;
}

@black: #2A2A33;@grayscalePlaceholders: #77787E;@grayscaleBorders: #DDE2ED;@grayscaleBackgrounds: #EFF2F9;@grayFocused: #C3C5CC;@greenMain: #40CD87;@greenLight: #F3F9F6;@redMain: #ED6868;@redLight: #FAF2F1;@yellowDark: #EBC40E;@yellowLight: #F7F5ED;@veryLightGray: #fafafa;@lightGrayishBlue: #e2e3ea;@blueMain: #D9E6E1;@davy-grey: #555555;@very-light-gray: #e3e3e3;@font-family: Roboto;@primary: #0f1c30;@primary-1: #0f1c3020;@primary-color-hover: #0f1c3060;@primary-color-active: #0f1c3060;@primary-color-outline: #0f1c3060;@primary-lightest: lighten(@primary, 30%);@primary-lighter: lighten(@primary, 10%);@primary-dark: darken(@primary, 8%);@primary-darkest: darken(@primary, 10%);@secondary: rgb(79, 93, 117);@secondary-lightest: lighten(@secondary, 60%);@secondary-lighter: lighten(@secondary, 35%);@secondary-darker: darken(@secondary, 8%);@secondary-darkest: darken(@secondary, 10%);@text-color: @primary;@text-color-disabled: @secondary-lighter;@button-color: @secondary-lightest;@button-color-disabled: @secondary-lighter;@layout-header-background: #0f1c30;@primary-color: @primary;@processing-color: @primary-color;@normal-color: #d9d9d9;@white: #fff;@link-color: @primary;@text-color-secondary: @secondary;@border-radius-base: 4px;@border-color-base: #DDE2ED;@secondary-hover-color: #7283a0;@mentions-dropdown-menu-item-hover-bg: rgb(114, 131, 160);@menu-item-active-bg: #95a1b850;@input-color: @text-color;@input-hover-border-color: #e2e3ea;@box-shadow-base: 0 1px 1px #e2e3ea75 inset, 0 0 8px #e2e3ea06;